// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
// import {database} from "firebase/database";
import { getAuth } from 'firebase/auth';

// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAP7yK9OcOlAuAk9maMNN8mmu95ockUw-U",
  authDomain: "demo.gotchu.co.nz",
  projectId: "gotchu-96383",
  storageBucket: "gotchu-96383.appspot.com",
  messagingSenderId: "203583237118",
  appId: "1:203583237118:web:50afc45dc0d83afb4b2eaf",
  measurementId: "G-YXKR1SLCPH"
};

initializeApp(firebaseConfig, {
  // persistence: browserSessionPersistence,
  // popupRedirectResolver: browserPopupRedirectResolver,
});
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
