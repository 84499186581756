<template>
<header class="navbar navbar-expand-lg px-2 px-xs-2 px-sm-3 px-md-5  fixed-top" :class="{ 'navbar-stuck': isScrolled }" data-bs-theme="light">
  <router-link class="navbar-brand" :to="currentUser != null ? '/dashboard' : '/'">
    <span class="text-primary flex-shrink-0 me-2">
      <img src="@/assets/img/logo.png" width="35" height="32" alt="">
    </span> Gotchu
  </router-link>

  <div class="form-check form-switch mode-switch order-lg-2 me-3 me-lg-4 ms-auto">
    <input class="form-check-input" type="checkbox" id="theme-mode1" :checked="isDarkMode" @change="toggleTheme">
    <label class="form-check-label" for="theme-mode1">
      <i class="ai-sun fs-lg"></i>
    </label>
    <label class="form-check-label" for="theme-mode1">
      <i class="ai-moon fs-lg"></i>
    </label>
  </div>
  <div v-if="currentUser != null" class="dropdown nav d-block order-lg-2 p-1">
      <a class="nav-link d-flex align-items-center p-0" href="#" data-bs-toggle="dropdown" aria-expanded="false">
        
        <div class="rounded-circle bg-size-cover bg-position-center flex-shrink-0 bg-secondary d-flex justify-content-center align-middle" >
          <i class="ai-user m-auto p-2"></i>
        </div>
        <div class="d-none d-sm-block ps-2">
          <div class="fs-xs lh-1 opacity-60">Hello,</div>
          <div class="fs-sm dropdown-toggle">{{currentUser.displayName}}</div>
        </div>
      </a>
      
      <div class="dropdown-menu dropdown-menu-end my-2">
        <h6 class="dropdown-header fs-xs fw-medium text-body-secondary text-uppercase pb-1">Account</h6>
        <router-link :to="{ name: 'UserDetail', params: { id: currentUser.uid } }" class="dropdown-item">
          <i class="ai-user-check fs-lg opacity-70 me-2"></i>
          Profile
        </router-link>
        <a class="dropdown-item" href="#">
          <i class="ai-lock-closed fs-lg opacity-70 me-2"></i>
          Lock
        </a>
        <router-link :to="{ name: 'ProfileSettings', params: { id: currentUser.uid } }" class="dropdown-item">
          <i class="ai-settings fs-lg opacity-70 me-2"></i>
          Settings
        </router-link>
        <div class="dropdown-divider"></div>
        <router-link class="dropdown-item" to="/logout" @click.prevent="logout">
          <i class="ai-logout fs-lg opacity-70 me-2"></i>
          Sign out
        </router-link>
      </div>
    </div>
        
  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse2" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
      
  <router-link to="/login" v-if="currentUser == null" class="btn btn-outline-primary btn-sm fs-sm order-lg-3 d-none d-lg-inline-flex">
    <i class="ai-login me-2 ms-n1"></i>
    Sign in
  </router-link>
  
  
  
  
  
  <nav class="collapse navbar-collapse" id="navbarCollapse2">
    <ul class="navbar-nav" v-if="menu">
      <menu-item v-for="(item, index) in filteredMenu" :key="index" :item="item"></menu-item>
    </ul>
    

    <router-link v-if="!loading && !currentUser" to="/login" class="btn btn-outline-primary btn-sm fs-sm d-lg-none ms-3 mb-4 mb-lg-0">
      <i class="ai-login me-2 ms-n1"></i>
      Sign in
    </router-link>

  </nav>
  
</header>
</template>

<script>
import MenuItem from './MenuItem.vue';
import { mapState } from 'vuex';


export default {
  components: {
    MenuItem,
  },
  data() {
    return {
      isScrolled: false,
      menu: [
        {name: 'Home',link: '/',},
        {name: 'Dashboard',link: '/dashboard', requiredAuth: true,},
        {name: 'User List',link: '/dashboard/user-list',requiredAuth: true,},
        {name: 'Support',link: '/dashboard/chats', badge: 0,},
        {name: 'Contact',link: '/contact',},
        // {
        //   name: 'Settings',
        //   // link: '/settings',
        //   children: [
        //     { name: 'Profile', link: '/settings/profile' },
        //     { name: 'Security', link: '/settings/security' },
        //     //<router-link :to="{ name: 'UserDetail', params: { id: user.id } }" class="btn btn-outline-secondary" type="button">View Details</router-link>
        //   ],
        // },
        // {name: 'UI Components',link: '/ui_kit/typography'},
      ]
    };
    },
    methods: {
        isActiveRoute(route) {
          return this.$route.path === route;
        },
        handleScroll() {
          this.isScrolled = window.scrollY > 0;
        },
        toggleTheme() {
          this.isDarkMode = !this.isDarkMode;
          const newTheme = this.isDarkMode ? 'dark' : 'light';

          const elements = document.querySelectorAll('[data-bs-theme]');
          elements.forEach((element) => {
            element.setAttribute('data-bs-theme', newTheme);
          });

          // localStorage.setItem('theme', newTheme);
          this.$store.dispatch('toggleTheme');
        },
        logout() {
          this.$store.dispatch('logout').then(() => {
            this.$router.push('/login');
          });
        },
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll);

      const savedTheme = localStorage.getItem('theme') || 'light';
      this.isDarkMode = savedTheme === 'dark';

      const elements = document.querySelectorAll('[data-bs-theme]');
      elements.forEach((element) => {
        element.setAttribute('data-bs-theme', savedTheme);
      });
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    computed: {
      ...mapState({
        loading: state => state.loading,
        currentUser: state => state.user ?? null,
      }),
      filteredMenu() {
        // Filter menu items based on the 'requiredAuth' field and 'currentUser'
        return this.menu.filter(item => {
          if (item.requiredAuth) {
            // Show only if currentUser is not null
            return this.currentUser != null;
          }
          return true; // Show all items that don't require authentication
        });
      },
    },
    created() {
      const savedTheme = localStorage.getItem('theme') || 'light';
      this.isDarkMode = savedTheme === 'dark';
      
    }
}
</script>

<style scoped>
</style>