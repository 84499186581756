import { createStore } from 'vuex';
import { collection, getDoc, doc, getDocs, orderBy, query } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { db } from '../firebase';



export default createStore({
  state: {
    theme: localStorage.getItem('theme') || 'light',
    loading: false,
    user: null,//log in or not
    locked: false,
    userProfile: null,//details about user

    users: [],

    // support chats
    supportChats: [],
    activeChatMessages: [],



    transactions: [],
    cards: [],
    // supportMessages: [],
  },
  mutations: {
    setTheme(state, theme) {
      state.theme = theme;
      localStorage.setItem('theme', theme);
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setLocked(state, locked) {
      state.locked = locked;
    },
    setUserProfile(state, user) {
      state.userProfile = user;
      state.loading = false;
    },
    setUser(state, user) {
      state.user = user;
      state.loading = false;
    },
    clearUser(state) {
      state.user = null;
      state.loading = false;
      state.userProfile = {}; // Reset any additional user profile data, if applicable
    },
    setTransactions(state, transactions) {
      state.transactions = transactions;
    },
    setCards(state, cards) {
      state.cards = cards;
    },
    setSupportChats(state, supportChats) {
      state.supportChats = supportChats;
    },
    setActiveChatMessages(state, messages){
      state.activeChatMessages = messages
    },
    // setSupportMessages(state, supportMessages) {
    //   state.supportMessages = supportMessages;
    // },
    setUsers(state, users) {
      state.users = users;
    },
    // SET_ACTIVE_CHAT_MESSAGES(state, messages) {
    //   state.activeChatMessages = messages;
    // },
    UPDATE_USER_PROFILE(state, updatedProfile) {
      // Merge the updated fields into the existing user profile
      state.userProfile = {
        ...state.userProfile,
        ...updatedProfile,
        // Optionally handle deep merging for nested objects
        communication: {
          ...state.userProfile.communication,
          ...updatedProfile.communication,
        },
        notifications: {
          ...state.userProfile.notifications,
          ...updatedProfile.notifications,
        },
      };
    },
    //
  },
  actions: {
    toggleTheme({ commit, state }) {
      const newTheme = state.theme === 'light' ? 'dark' : 'light';
      commit('setTheme', newTheme);
      document.querySelector('#app').setAttribute('data-bs-theme', newTheme);
    },
    unlock({ commit }, locked) {
      commit('setLocked', locked);
    },
    
    checkAuth({ commit }) {
      commit('setLoading', true);

      // set time when login
      // check time difference 
      return new Promise((resolve, reject) => {
        

        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
          if (user) {
            commit('setUser', user);
            resolve(user);
          } else {
            commit('clearUser');
            resolve(null);
          }
        });

        console.log(reject);
        
      });
    },
    // async logout({ commit }) {
    //   const auth = getAuth();
    //   auth.signOut(auth)
    //   .then(() => {
    //     commit('clearUser');
    //     localStorage.removeItem('users');
    //   })
    //   .catch((error) => {
    //     console.error('Error during sign out:', error);
    //   });
    // },
    async logout({ commit }) {
      const auth = getAuth();
      try {
        await auth.signOut(); // Await the sign-out to ensure it's completed
        commit('clearUser'); // Clear the user data in the Vuex state
        localStorage.removeItem('users'); // Clear user data in localStorage
        console.log('User signed out successfully');
      } catch (error) {
        console.error('Error during sign out:', error);
      }
    },


    async fetchUsers({ commit }) {
      commit('setLoading', true);
      // Check if users are stored in local storage
      const localStorageKey = 'users';
      const storedUsers = localStorage.getItem(localStorageKey);
      if (storedUsers) {
        // If users are found in local storage, commit them to the store
        commit('setUsers', JSON.parse(storedUsers));
      } else {

        try {
          const usersCollection = collection(db, 'users');
          const snapshot = await getDocs(usersCollection);
  
          const users = [];
          snapshot.forEach((doc) => {
            users.push({ id: doc.id, ...doc.data() });
          });
          // Store the fetched users in local storage
          localStorage.setItem(localStorageKey, JSON.stringify(users));
          commit('setUsers', users);
        } catch (error) {
          console.error('Error fetching users:', error);
        }
        //
      }

      commit('setLoading', false);
    },

    async fetchUserById({ commit, getters }, userId){
      console.log('fetch user by id', userId);
      
      const localStorageKey = 'users';
      const storedUsers = localStorage.getItem(localStorageKey);

      if (storedUsers && 1 == 2) { // !!!
        
        // If users are found in local storage, commit them to the store
        // commit('setUsers', JSON.parse(storedUsers));
        
        console.log('we have some users saved, lets see if one we need is here', userId);
        const userFromStore = getters.users.find(user => user.id == userId);

        if(userFromStore){
          console.log('user from store', userFromStore);
          
        }else{
          console.log('not here', userFromStore);
          
        }
        
      } else {

        //hard fetch

        try {
          const usersCollection = collection(db, 'users');
          const snapshot = await getDocs(usersCollection);

          const users = [];
          snapshot.forEach((doc) => {
            users.push({ id: doc.id, ...doc.data() });
          });
          // Store the fetched users in local storage
          localStorage.setItem(localStorageKey, JSON.stringify(users));
          commit('setUsers', users);
        } catch (error) {
          console.error('Error fetching users:', error);
        }

        //
      }

      

    },

    // User Detailed Profile
    async fetchUserProfile({ commit, getters }, userId) {
      commit('setLoading', true);
      console.log('fetchUserProfile');
      
      // Check if the user profile exists in the users array in the Vuex store
      const userFromStore = getters.users.find(user => user.id === userId);
    
      if (userFromStore && 1 == 2) {// !!! never
        // If the user is found in the users array, commit it to the store
        commit('setUserProfile', userFromStore);
      } else {
        // Check if the user profile is stored in local storage
        const localStorageKey = `userProfile_${userId}`;
        const storedUserProfile = localStorage.getItem(localStorageKey);
    
        if (storedUserProfile) {
          // If the user profile is found in local storage, commit it to the store
          commit('setUserProfile', JSON.parse(storedUserProfile));
        } else {
          try {
            // Otherwise, fetch the user profile from Firebase
            const userRef = doc(db, 'users', userId);
            const userSnap = await getDoc(userRef);
            
            if (userSnap.exists()) {
              const userData = { id: userId, ...userSnap.data() };
    
              // Store the fetched user profile in local storage
              localStorage.setItem(localStorageKey, JSON.stringify(userData));
    
              // Commit the user profile to the Vuex store
              commit('setUserProfile', userData);
    
              // Fetch additional user data
              await Promise.all([
                this.dispatch('fetchUserTransactions', userId),
                this.dispatch('fetchUserCards', userId),
                this.dispatch('fetchUserSupportMessages', userId)
              ]);
            } else {
              console.error('No such user!');
              commit('setUserProfile', null);
            }
          } catch (error) {
            console.error('Error fetching user profile:', error);
          }
        }
      }
    
      commit('setLoading', false);
    },


    // User Profile extra
    async fetchUserTransactions({ commit }, userId) {
      console.log('getting transactions');
      
      const querySnapshot = await getDocs(collection(db, `users/${userId}/transactions`));
      const transactions = [];
      querySnapshot.forEach((doc) => {
        transactions.push({ id: doc.id, ...doc.data() });
      });
      console.log('got transactions', transactions);
      
      commit('setTransactions', transactions);
    },
    async fetchUserCards({ commit }, userId) {
      // commit('setLoading', true);
      const querySnapshot = await getDocs(collection(db, `users/${userId}/cards`));
      const cards = [];
      querySnapshot.forEach((doc) => {
        cards.push({ id: doc.id, ...doc.data() });
      });
      commit('setCards', cards);
    },

    async fetchUserSupportMessages({ commit }, userId) {
      // commit('setLoading', true);
      // const querySnapshot = await getDocs(collection(db, `users/${userId}/support_messages`));
      const messagesCollection = collection(db, `users/${userId}/support_messages`);
      const messagesQuery = query(messagesCollection, orderBy('dateNow', 'asc'));

      const querySnapshot = await getDocs(messagesQuery);
      const supportMessages = [];
      querySnapshot.forEach((doc) => {
        supportMessages.push({ id: doc.id, ...doc.data() });
      });
      commit('setSupportMessages', supportMessages);
    },


    // Support Chat
    async fetchSupportChats({commit}){
      const querySnapshot = await getDocs(collection(db, `support_messages`));
      const supportChats = [];
      querySnapshot.forEach((doc) => {
        supportChats.push({ id: doc.id, ...doc.data() });
      });
      // console.log(supportChats);
      commit('setSupportChats', supportChats);
    },

    async fetchSupportChatMessages({commit}, chatId){
      console.log('fetch chat messages');
      
      const querySnapshot = await getDocs(collection(db, `support_messages/${chatId}/messages`));
      const activeChatMessages = [];
      querySnapshot.forEach((doc) => {
        activeChatMessages.push({ id: doc.id, ...doc.data() });
      });
      // console.log(activeChatMessages);
      
      
      commit('setActiveChatMessages', activeChatMessages.sort((a, b) => {
        return a.dateNow.seconds - b.dateNow.seconds;
      }));
    },


    updateUserProfile({ commit }, updatedProfile) {
      commit('UPDATE_USER_PROFILE', updatedProfile);
    },


  },
  getters: {
    users: (state) => state.users,
    userById: (state) => (id) => {
      return state.users.find(user => user.id == id);
    },
    loading: (state) => state.loading,
    locked: (state) => state.locked,
    getUser: (state) => state.user,
    getUserProfile: (state) => state.userProfile,
    getTransactions:(state) => state.transactions,
    getCards:(state) => state.cards,
    getSupportMessages:(state) => state.supportMessages,
    getSupportChats:(state) => state.supportChats,
  }
});
