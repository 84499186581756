<template>
  <nav aria-label="breadcrumb">
    <ol class="pt-lg-3 pb-lg-4 pb-2 breadcrumb">
      <li v-for="(breadcrumb, index) in breadcrumbs" :key="index" class="breadcrumb-item">
        <router-link v-if="index < breadcrumbs.length - 1" :to="breadcrumb.path">{{ breadcrumb.name }}</router-link>
        <span v-else aria-current="page">{{ breadcrumb.name }}</span>
      </li>
    </ol>
  </nav>
</template>

<script>
import { computed } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'BreadcrumbNavigation',
  setup() {
    const router = useRouter();
    const allRoutes = router.getRoutes();

    const breadcrumbs = computed(() => {
      const matchedRoutes = router.currentRoute.value.matched;
      const breadcrumbs = [];

      matchedRoutes.forEach((route, index) => {
        const breadcrumb = route.meta.breadcrumb;
        if (breadcrumb) {
          console.log('bread', breadcrumb, route.name, route.name === 'UserDetail');

          console.log(router.getRoutes());
          
          
          
          if (route.name === 'UserDetail') {
            // Handle UserDetail specifically
            // const userListRoute = router.getRoutes().find(r => r.name === 'UserList');
            const userListRoute = allRoutes.find(route => route.name === 'User List');
            // const userListRoute = router.getRoutes().find(r => r.path === 'user-list/');
            console.log(userListRoute);
            
            if (userListRoute) {
              breadcrumbs.push({
                name: userListRoute.meta.breadcrumb,
                path: userListRoute.path,
              });
            }
          }
          
          breadcrumbs.push({
            name: breadcrumb,
            path: index < matchedRoutes.length - 1 ? route.path : null,
          });
        }
      });

      return breadcrumbs;
    });

    return {
      breadcrumbs,
    };
  },
};
</script>

<style>
.breadcrumb {
  background-color: transparent;
  padding: 0;
  margin-bottom: 1rem;
}
</style>
