<template>
    <li v-if="!item.children" class="nav-item">
      <router-link class="nav-link" :to="item.link">
        {{ item.name }}
        <span v-if="item.badge" class="badge bg-info ms-2">{{item.badge}}</span>
      </router-link>
    </li>
    <li v-else class="nav-item dropdown">
      <a
        class="nav-link dropdown-toggle"
        to=""
        id="navbarDropdownMenuLink"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {{ item.name }}
    </a>
      <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
        <li v-for="(child, index) in item.children" :key="index">
          <router-link class="dropdown-item" :to="child.link">
            {{ child.name }}
          </router-link>
        </li>
      </ul>
    </li>
</template>

<script>
  export default {
    props: {
      item: {
        type: Object,
        // required: true,
      },
    },
  };
</script>

<style scoped>
.nav-link:active{
    display: flex;
  align-items: center;
  background: none;
  border: 0;
}
.nav-link:focus{}
</style>
  