<template>
  <div class="container pt-5 pb-lg-5 pb-md-4 pb-2 my-5">
    <div class="row mb-md-2 mb-xl-4">
      <div class="pe-lg-4 pe-xl-5">
        
          <BreadcrumbNavigation />
          
          <router-view/>
      </div>
      
    </div>
  </div>
</template>
  
  <script>
  import BreadcrumbNavigation from '@/components/BreadcrumbNavigation.vue';


  export default {
    name: 'InternalLayout',
    components: {
      BreadcrumbNavigation
    },
  };
  </script>
  
  
  <style>
  </style>
  