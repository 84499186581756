<template>
  <footer class="footer bg-secondary py-5" data-bs-theme="light">
      <div class="container pt-md-2 pt-lg-3 pt-xl-4">
        <div class="row pb-5 pt-sm-2 mb-lg-2">
          <div class="col-md-4 col-lg-3 pb-2 pb-md-0 mb-4 mb-md-0">
            <a class="navbar-brand" href="#">
              <span class="text-primary flex-shrink-0 me-2">
                <img src="@/assets/img/logo.svg" width="35" height="35" alt="">
              </span> Gotchu
            </a>
            <p class="text-body fs-sm pb-2 pb-md-3 mb-3">
              Gotchu is the app that provides instant financial relief. Borrow up to $500 with just a few taps on your phone.
            </p>
            <div class="d-flex">
              <a class="btn btn-icon btn-sm btn-secondary btn-facebook rounded-circle me-3" href="#" aria-label="Facebook">
                <i class="ai-facebook"></i>
              </a>
              <a class="btn btn-icon btn-sm btn-secondary btn-instagram rounded-circle me-3" href="#" aria-label="Instagram">
                <i class="ai-instagram"></i>
              </a>
              <a class="btn btn-icon btn-sm btn-secondary btn-linkedin rounded-circle" href="#" aria-label="LinkedIn">
                <i class="ai-linkedin"></i>
              </a>
            </div>
          </div>
          <div class="col-md-8 col-lg-7 col-xl-6 offset-lg-2 offset-xl-3">
            <div class="row row-cols-1 row-cols-sm-3">
              <div class="col mb-4 mb-md-0">

              </div>
              <div class="col mb-4 mb-md-0">
                <h4 class="h6 fw-bold pb-lg-1">Support</h4>
                <ul class="nav flex-column">
                  <li v-for="item in menuItems" :key="item.route">
                    <router-link :to="item.route" class="nav-link fw-normal py-1 px-0">
                      {{ item.name }}
                    </router-link>
                  </li>
                </ul>
              </div>
              <div class="col">
                <h4 class="h6 fw-bold pb-2 mb-0 mb-lg-1">Install App</h4>
                <a class="btn btn-secondary px-3 py-2 mt-3 me-3 me-md-0" href="#">
                  <img class="mx-1 d-dark-mode-none" src="@/assets/img/market/appstore-dark.svg" width="120" alt="App Store">
                  <img class="mx-1 d-none d-dark-mode-block" src="@/assets/img/market/appstore-light.svg" width="120" alt="App Store">
                </a>
                <a class="btn btn-secondary px-3 py-2 mt-3 me-3 me-md-0" href="#">
                  <img class="mx-1 d-dark-mode-none" src="@/assets/img/market/googleplay-dark.svg" width="119" alt="Google Play">
                  <img class="mx-1 d-none d-dark-mode-block" src="@/assets/img/market/googleplay-light.svg" width="119" alt="Google Play">
                </a>
              </div>
            </div>
          </div>
        </div>
        <p class="nav fs-sm mb-0">
          <span class="text-body-secondary me-1">© All rights reserved.</span>
        </p>
      </div>
    </footer>
</template>

<script>
export default {
    name: 'FooterBlock',
    data() {
    return {
      menuItems: [
        { name: 'Help Center', route: '/help-center' },
        { name: 'Terms and Conditions', route: '/docs/terms-and-conditions' },
        { name: 'Loan Contract', route: '/docs/loan-contract' },
        { name: 'Privacy Policy', route: '/docs/privacy-policy' },
        { name: 'Cancellation Policy', route: '/docs/cancellation-policy' },
      ]
    };
  }
}
</script>

<style>

</style>