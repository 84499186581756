<template>
  <aside class="container position-fixed d-none d-lg-block start-50 translate-middle-x h-100 pt-5">
    <div class="row h-100 pt-5">
      <div class="col-lg-3 d-flex flex-column pb-3 pt-4" style="height: calc(100% - 2rem);">

        <div class="position-relative">
                  <i class="ai-search position-absolute top-50 start-0 translate-middle-y ms-3"></i>
                  <input class="form-control ps-5" type="search" placeholder="Enter keyword">
                </div>


        <div class="h-100 mt-2 simplebar-scrollable-y">
          <h3 class="h5 mb-1 pb-2">Content</h3>

            <ul class="nav flex-column">

              <li v-for="(item, index) in menuItems" :key="index" class="nav-item">

                <router-link :to="item.link" class="nav-link fs-sm fw-normal py-1 ps-1 pe-0 mb-1">
                  <i class="ai-circle-arrow-down fs-lg opacity-90 me-2"></i>


                  {{ item.title }}

                </router-link>

                <ul v-if="item.children && $route.path == item.link"
                  class="nav flex-column border-start ps-3 ms-2 mb-2">
                  <li v-for="(child, childIndex) in item.children" :key="childIndex" class="nav-item">
                    <router-link :to="item.link + child.link" class="nav-link fs-sm fw-normal py-1 ps-1 pe-0 mb-1"
                      active-class="" exact-active-class="">
                      {{ child.title }}
                    </router-link>
                  </li>
                </ul>

              </li>

            </ul>

            <h3 class="h5 mb-1 pt-4 pb-2">Components</h3>
            <ul class="nav flex-column">
              <li v-for="(item, index) in componentItems" :key="index" class="nav-item">
                <router-link :to="item.link" class="nav-link fs-sm fw-normal py-1 ps-1 pe-0 mb-1">
                  <i class="fs-lg opacity-90 me-2" :class="$route.path == item.link ? 'ai-circle-arrow-down' : 'ai-circle-arrow-right'"></i> {{ item.title }}
                </router-link>

                <ul v-if="item.children && $route.path == item.link"
                  class="nav flex-column border-start ps-3 ms-2 mb-2">
                  <li v-for="(child, childIndex) in item.children" :key="childIndex" class="nav-item">

                    <router-link :to="item.link + child.link" class="nav-link fs-sm fw-normal py-1 ps-1 pe-0 mb-1" exact-active-class="" active-class="">
                      {{ child.title }}
                    </router-link>

                  </li>
                </ul>

              </li>
            </ul>
          </div>
      </div>
    </div>
  </aside>
</template>

<script>
  // import Simplebar from 'simplebar-vue';
  // import { PerfectScrollbar } from 'vue3-perfect-scrollbar'
  // import 'vue-perfect-scrollbar/dist/vue-perfect-scrollbar.css';s


  export default {
    data() {
      return {
        menuItems: [
          {
            title: "Typography",
            link: "/ui_kit/typography",
            children: [
              { title: "Headings", link: "#type-headings" },
              { title: "Display headings", link: "#type-displays" },
              { title: "Body text sizes", link: "#type-body-text" },
              { title: "Inline text elements", link: "#type-inline" },
              { title: "Abbreviations", link: "#type-abbreviations" },
              { title: "Blockquote", link: "#type-blockquote" },
              { title: "Lists: Unordered and ordered", link: "#type-lists" },
              { title: "Lists: Unstyled", link: "#type-lists-unstyled" },
              { title: "Lists: Inline", link: "#type-lists-inline" },
              { title: "Description list basic example", link: "#type-description-list" },
              { title: "Description list alignment", link: "#type-description-list-align" }
            ]
          },
          {
            title: "Icon font",
            link: "/ui_kit/icon-font",
            children: [
              { title: "UI Icons", link: "#ui-icons" },
              { title: "Brand icons", link: "#brand-icons" },
              { title: "Yoga demo icons", link: "#yoga-icons" },
              { title: "Coworking demo icons", link: "#coworking-icons" },
              { title: "Influencer demo icons", link: "#influencer-icons" }
            ],
          },
          {
            title: "Code",
            link: "/ui_kit/code",
            children: [
              { title: "Inline code", link: "#code-inline" },
              { title: "User input", link: "#code-user-input" },
              { title: "Variables", link: "#code-variables" },
              { title: "Code block", link: "#code-block" },
              { title: "Code block scrollable", link: "#code-scrollable" },
              { title: "Code highlighting", link: "#code-highlighting" }
            ],
          },
          {
            title: "Images & figures",
            link: "/ui_kit/images",
            children: [
              { title: "Image shapes", link: "#image-shapes" },
              { title: "Thumbnails", link: "#image-thumbnails" },
              { title: "Figures with caption", link: "#image-figures" },
              { title: "Image swap on hover", link: "#image-swap" }
            ],
          },
          {
            title: "Tables",
            link: "/ui_kit/tables",
            children: [
              { title: "Basic example", link: "#tables-basic" },
              { title: "Dark table", link: "#tables-dark" },
              { title: "Striped rows", link: "#tables-striped-rows" },
              { title: "Striped columns", link: "#tables-striped-columns" },
              { title: "Bordered table", link: "#tables-bordered" },
              { title: "Hoverable rows", link: "#tables-hoverable" },
              { title: "Contextual colors", link: "#tables-contextual" },
              { title: "Color borders", link: "#tables-color-borders" }
            ],
          }
        ],
        //
        componentItems: [
          {
            title: "Accordion",
            link: "/ui_kit/accordion",
            children: [
              { title: "Default style", link: "#default" },
              { title: "Alternative Style", link: "#alternative" },
            ]
          },
          {
            title: "Alerts",
            link: "/ui_kit/alerts",
            children: [
              { title: "Basic example", link: "#basic" },
              { title: "Alert with icon", link: "#icon" },
              { title: "Dismissible alerts", link: "#dismissible" },
              { title: "Additional content", link: "#additional" },
            ]
          },
          {
            title: "Badges",
            link: "/ui_kit/badges",
            children: [
              { title: "Solid", link: "#badges-solid" },
              { title: "Outline", link: "#badges-outline" },
              { title: "Translucent", link: "#badges-translucent" },
              { title: "Inside heading", link: "#badges-heading" },
              { title: "Inside button", link: "#badges-button" },
              { title: "Inside list group", link: "#badges-list-group" },
            ]
          },
          {
            title: "Blog components",
            link: "/ui_kit/blog",
            children: [
              { title: "Post preview (Grid)", link: "#blog-grid" },
              { title: "Post preview (List)", link: "#blog-list" },
              { title: "Post preview (Simple)", link: "#blog-simple" },
              { title: "Featured posts widget", link: "#blog-posts-widget" },
              { title: "Categories widget", link: "#blog-categories-widget" },
              { title: "Author widget style 1", link: "#blog-author-widget-1" },
              { title: "Author widget style 2", link: "#blog-author-widget-2" },
              { title: "Post comments", link: "#blog-comments" }
            ],
          },
          {
            title: "Breadcrumb",
            link: "/ui_kit/breadcrumbs",
          },
          {
            title: "Buttons",
            link: "/ui_kit/buttons",
            children: [
              { title: "Solid (default)", link: "#buttons-solid" },
              { title: "Outline", link: "#buttons-outline" },
              { title: "Icons", link: "#buttons-icons" },
              { title: "Market buttons", link: "#buttons-market" },
              { title: "Shapes", link: "#buttons-shapes" },
              { title: "Sizes", link: "#buttons-sizes" },
              { title: "States", link: "#buttons-states" }
            ]
          },
          {
            title: "Button group",
            link: "/ui_kit/button-group",
            children: [
              { title: "Basic example", link: "#btn-group-basic" },
              { title: "Button toolbar", link: "#btn-group-toolbar" },
              { title: "Nested dropdown", link: "#btn-group-dropdown" },
              { title: "Sizing", link: "#btn-group-sizing" }
            ],
          },
          {
            title: "Cards:Basic",
            link: "/ui_kit/cards-basic",
            children: [
              { title: "No image", link: "#card-no-image" },
              { title: "Alternative card style", link: "#card-alt-style" },
              { title: "Image on top", link: "#card-top-image" },
              { title: "Image on bottom", link: "#card-bottom-image" },
              { title: "Horizontal layout", link: "#card-horizontal" },
              { title: "Header and footer", link: "#card-header-footer" },
              { title: "Text alignment", link: "#card-text-alignment" },
              { title: "Navigation: Tabs", link: "#card-nav-tabs" },
              { title: "Navigation: Pills", link: "#card-nav-pills" },
              { title: "List group inside card", link: "#card-list-group" },
              { title: "Card styles: Solid background", link: "#card-bg-solid" },
              { title: "Card styles: Faded background and color", link: "#card-bg-faded" },
              { title: "Card styles: Border and color", link: "#card-border-color" },
              { title: "Layout: Card group", link: "#card-group" },
              { title: "Layout: Masonry grid", link: "#card-grid" }
            ],
          },
          {
            title: "Cards:Hover effects",
            link: "/ui_kit/cards-hover",
            children: [
              { title: "Image zoom", link: "#card-image-zoom" },
              { title: "Lift", link: "#card-lift" },
              { title: "Flip 1", link: "#card-flip-1" },
              { title: "Flip 2", link: "#card-flip-2" },
              { title: "Color", link: "#card-color" },
              { title: "Content overlay", link: "#card-content-overlay" },
              { title: "Image overlay 1", link: "#card-image-1" },
              { title: "Image overlay 2", link: "#card-image-2" }
            ],
          },
          {
            title: "Carousel (Slide)",
            link: "/ui_kit/carousel",
            children: [
              { title: "Navigation (Prev / next buttons)", link: "#carousel-navigation" },
              { title: "Pagination", link: "#carousel-pagination" },
              { title: "Multiple slides (Responsive) + Controls and pagination outside", link: "#carousel-multiple-slides" },
              { title: "Fade transition", link: "#carousel-fade" },
              { title: "Thumbnails", link: "#carousel-thumbnails" },
              { title: "Link slides to outside content", link: "#carousel-link-to-content" }
            ],
          },
          {
            title: "Charts",
            link: "/ui_kit/charts",
            children: [
              { title: "Line chart", link: "#chart-line" },
              { title: "Bar chart", link: "#chart-bar" },
              { title: "Pie chart", link: "#chart-pie" },
              { title: "Doughnut chart", link: "#chart-doughnut" },
              { title: "Polar area chart", link: "#chart-polar" },
              { title: "Radar chart", link: "#chart-radar" }
            ],
          },
          {
            title: "Collapse",
            link: "/ui_kit/collapse",
            children: [
              { title: "Basic example", link: "#collapse-basic" },
              { title: "Horizontal", link: "#collapse-horizontal" },
              { title: "Multiple targets", link: "#collapse-multiple" }
            ],
          },
          {
            title: "Countdown",
            link: "/ui_kit/countdown",
            children: [
              { title: "Basic example", link: "#countdown-basic" },
              { title: "Display no years", link: "#countdown-no-years" },
              { title: "Sizing", link: "#countdown-sizing" },
              { title: "Colors", link: "#countdown-colors" },
              { title: "Cards example", link: "#countdown-cards" }
            ],
          },
          {
            title: "Date /time picker",
            link: "/ui_kit/date-picker",
            children: [
              { title: "Date picker", link: "#picker-date" },
              { title: "Date and time picker", link: "#picker-date-time" },
              { title: "Min date + default date", link: "#picker-min-default-date" },
              { title: "Date range", link: "#picker-date-range" }
            ]
          },
          {
            title: "Dropdowns",
            link: "/ui_kit/dropdowns",
            children: [
              { title: "Static examples", link: "#dropdowns-static" },
              { title: "Dark dropdowns", link: "#dropdowns-dark" },
              { title: "Forms inside dropdown", link: "#dropdowns-forms" },
              { title: "Directions", link: "#dropdowns-directions" },
              { title: "Menu alignment", link: "#dropdowns-alignment" },
              { title: "Split button", link: "#dropdowns-split-button" }
            ]
          },
          {
            title: "Event calendar",
            link: "/ui_kit/calendar",
          },
          {
            title: "Forms",
            link: "/ui_kit/forms",
            children: [
              { title: "Supported input types", link: "#forms-types" },
              { title: "Floating labels", link: "#forms-floating-labels" },
              { title: "Password visibility toggle", link: "#forms-password-toggle" },
              { title: "Checkboxes", link: "#forms-checkboxes" },
              { title: "Radio buttons", link: "#forms-radios" },
              { title: "Switches", link: "#forms-switches" },
              { title: "Theme mode switch", link: "#forms-mode-switch" },
              { title: "Button toggles (Color, size options)", link: "#forms-btns" },
              { title: "Count input (+/-)", link: "#forms-count" },
              { title: "Range slider", link: "#forms-range-slider" },
              { title: "Sizing", link: "#forms-sizing" },
              { title: "Readonly & Disabled", link: "#forms-disabled" },
              { title: "Inline form", link: "#forms-inline" },
              { title: "Help text", link: "#forms-help-text" },
              { title: "Validation: status text", link: "#forms-validation-text" },
              { title: "Validation: status tooltips", link: "#forms-validation-tooltips" },
              { title: "Input mask (Formatted content)", link: "#forms-formatted" }
            ]
          },
          {
            title: "Gallery (Lightbox)",
            link: "/ui_kit/gallery",
            children: [
              { title: "Media: Image", link: "#gallery-image" },
              { title: "Media: Video", link: "#gallery-video" },
              { title: "Media: Iframe (Google map)", link: "#gallery-iframe" },
              { title: "Zoom effect", link: "#gallery-zoom" },
              { title: "Thumbnails", link: "#gallery-thumbnails" },
              { title: "Inside card", link: "#gallery-card" }
            ]
          },
          {
            title: "Hotspots",
            link: "/ui_kit/hotspots",
            children: [
              { title: "Style 1", link: "#hotspots-style-1" },
              { title: "Style 2", link: "#hotspots-style-2" }
            ]
          },
          {
            title: "Image comparisonslider",
            link: "/ui_kit/image-comparison-slider",
          },
          {
            title: "Input group",
            link: "/ui_kit/input-group",
            children: [
              { title: "Addon position", link: "#ig-addon-position" },
              { title: "Multiple addons", link: "#ig-addon-multiple" },
              { title: "Different types", link: "#ig-addon-types" },
              { title: "Multiple inputs", link: "#ig-multiple-inputs" },
              { title: "Button addons", link: "#ig-addon-button" },
              { title: "Pill shape", link: "#ig-pill" },
              { title: "Sizing", link: "#ig-sizing" }
            ]
          },
          {
            title: "List group",
            link: "/ui_kit/list-group",
            children: [
              { title: "Basic example", link: "#lg-basic" },
              { title: "Links or buttons", link: "#lg-links" },
              { title: "Flush", link: "#lg-flush" },
              { title: "With icons & badges", link: "#lg-icons" },
              { title: "Checkboxes & radios", link: "#lg-checkboxes" },
              { title: "Numbered list group", link: "#lg-numbered" },
              { title: "Horizontal list group", link: "#lg-horizontal" },
              { title: "Actionable horizontal list group", link: "#lg-horizontal-action" },
              { title: "Custom content", link: "#lg-custom-content" },
              { title: "JavaScript behavior", link: "#lg-javascript" }
            ]
          },
          {
            title: "Maps",
            link: "/ui_kit/maps",
            children: [
              { title: "Basic example (no options passed)", link: "#map-basic" },
              { title: "Custom options", link: "#map-options" },
              { title: "Alternative map style", link: "#map-style" }
            ]
          },
          {
            title: "Modal",
            link: "/ui_kit/modal",
            children: [
              { title: "Modal markup", link: "#modal-markup" },
              { title: "Tabs and forms", link: "#modal-tabs-forms" },
              { title: "Live demo (variants)", link: "#modal-demo" }
            ]
          },
          {
            title: "Navbar",
            link: "/ui_kit/navbar",
            children: [
              { title: "Supported content", link: "#navbar-content" },
              { title: "Color schemes", link: "#navbar-colors" },
              { title: "Responsive offcanvas menu", link: "#navbar-offcanvas" }
            ]
          },
          {
            title: "Offcanvas",
            link: "/ui_kit/offcanvas",
          children: [
              { title: "Offcanvas components", link: "#offcanvas-components" },
              { title: "Placement", link: "#offcanvas-placement" },
              { title: "Mobile menu offcanvas (responsive)", link: "#offcanvas-mobile" }
            ]
          },
          {
            title: "Pagination",
            link: "/ui_kit/pagination",
            children: [
              { title: "Basic example", link: "#pagination-basic" },
              { title: "With icons", link: "#pagination-icons" },
              { title: "Sizing", link: "#pagination-sizing" },
              { title: "Items per page selector", link: "#pagination-items" }
            ]
          },
          {
            title: "Parallax",
            link: "/ui_kit/parallax",
            children: [
              { title: "Background image", link: "#parallax-background" },
              { title: "Cursor position parallax", link: "#parallax-cursor" }
            ]
          },
          {
            title: "Pills",
            link: "/ui_kit/pills",
            childrenl: [
              { title: "Basic example", link: "#pills-basic" },
              { title: "Rounded pills", link: "#pills-rounded" },
              { title: "Fill and justify", link: "#pills-fill" },
              { title: "Pills with dropdowns", link: "#pills-dropdown" },
              { title: "Vertical pills", link: "#pills-vertical" },
              { title: "Tabs-like behavior", link: "#pills-tabs" }
            ]
          },
          {
            title: "Placeholders",
            link: "/ui_kit/placeholders",
            children: [
              { title: "Width", link: "#placeholders-width" },
              { title: "Color", link: "#placeholders-color" },
              { title: "Sizing", link: "#placeholders-sizing" },
              { title: "Animation", link: "#placeholders-animation" },
              { title: "Use case example", link: "#placeholders-example" }
            ]
          },
          {
            title: "Popovers",
            link: "/ui_kit/popovers",
            children: [
              { title: "Static popovers", link: "#popovers-static" },
              { title: "Live demo", link: "#popovers-demo" },
              { title: "Toggle on click / hover", link: "#popovers-toggle" }
            ]
          },
          {
            title: "Portfolio component",
            link: "/ui_kit/portfolio-components",
            children: [
              { title: "Grid view style 1", link: "#portfolio-grid-1" },
              { title: "Grid view style 2", link: "#portfolio-grid-2" },
              { title: "Grid view style 3", link: "#portfolio-grid-3" },
              { title: "List view style 1", link: "#portfolio-list-1" },
              { title: "List view style 2", link: "#portfolio-list-2" }
            ]
          },
          {
            title: "Pricing",
            link: "/ui_kit/pricing",
            children: [
              { title: "Cards: Style 1", link: "#pricing-cards-1" },
              { title: "Cards: Style 2", link: "#pricing-cards-2" },
              { title: "List", link: "#pricing-list" },
              { title: "Table", link: "#pricing-table" },
              { title: "Price switch", link: "#pricing-switch" }
            ]
          },
          {
            title: "Progress",
            link: "/ui_kit/progress",
            children: [
              { title: "Color variation (thick)", link: "#progress-colors-thick" },
              { title: "Color variation (thin)", link: "#progress-colors-thin" },
              { title: "Multiple bars", link: "#progress-multiple" },
              { title: "Animated striped bars", link: "#progress-animated" },
              { title: "Use case: Rating breakdown", link: "#progress-rating-breakdown" }
            ]
          },
          {
            title: "Shop Components",
            link: "/ui_kit/shop-components",
            children: [
              { title: "Product card", link: "#shop-product-card" },
              { title: "Tiny horizontal card", link: "#shop-horizontal-card" },
              { title: "Category card", link: "#shop-category-card" },
              { title: "Shopping cart item", link: "#shop-cart-item" },
              { title: "Categories filter", link: "#shop-categories-filter" },
              { title: "Price range slider", link: "#shop-price-range" },
              { title: "Color filter (custom checkboxes)", link: "#shop-color-filter" }
            ]
          },
          {
            title: "Social Buttons",
            link: "/ui_kit/social-buttons",
            children: [
              { title: "Solid buttons", link: "#sb-solid" },
              { title: "Outline buttons", link: "#sb-outline" },
              { title: "Shape", link: "#sb-shape" },
              { title: "Size", link: "#sb-size" }
            ]
          },
          {
            title: "Spiners",
            link: "/ui_kit/spinners",
            children: [
              { title: "Border spinner", link: "#spinners-border" },
              { title: "Growing spinner", link: "#spinners-growing" },
              { title: "Size", link: "#spinners-size" },
              { title: "Inside button", link: "#spinners-button" }
            ]
          },
          {
            title: "Steps",
            link: "/ui_kit/steps",
            children: [
              { title: "Horizontal layout", link: "#steps-horizontal" },
              { title: "Animate on hover", link: "#steps-hover" },
              { title: "Alignment", link: "#steps-alignment" },
              { title: "Vertical layout", link: "#steps-vertical" },
              { title: "No connector", link: "#steps-no-connector" },
              { title: "Card style", link: "#steps-cards" },
              { title: "Icons instead of numbers", link: "#steps-icons" }
            ]
          },
          {
            title: "Tabs",
            link: "/ui_kit/tabs",
            children: [
              { title: "With icons and dropdown", link: "#tabs-icon-dropdown" },
              { title: "Pill shape", link: "#tabs-pill-shape" },
              { title: "Alignment and fill", link: "#tabs-alignment" },
              { title: "Vertical tabs", link: "#tabs-vertical" },
              { title: "Image tabs", link: "#tabs-image" }
            ]
          },
          {
            title: "Team",
            link: "/ui_kit/team",
            children: [
              { title: "Style 1", link: "#team-1" },
              { title: "Style 2", link: "#team-2" },
              { title: "Style 3", link: "#team-3" },
              { title: "Style 4", link: "#team-4" }
            ]
          },
          {
            title: "Testimonials / Reviews",
            link: "/ui_kit/testimonials",
            children: [
              { title: "Quotation", link: "#quotation" },
              { title: "Testimonial: Style 1", link: "#testimonial-1" },
              { title: "Testimonial: Style 2", link: "#testimonial-2" },
              { title: "Testimonial: Style 3", link: "#testimonial-3" },
              { title: "Testimonial: Style 4", link: "#testimonial-4" },
              { title: "Review", link: "#review" },
              { title: "Testimonials slider: Style 1", link: "#testimonials-slider-1" },
              { title: "Testimonials slider: Style 2", link: "#testimonials-slider-2" }
            ]
          },
          {
            title: "Toasts",
            link: "/ui_kit/toasts",
            children: [
              { title: "Basic example", link: "#toast-basic" },
              { title: "Color variations", link: "#toast-colors" }
            ]
          },
          {
            title: "Tooltips",
            link: "/ui_kit/tooltips",
            children: [
              { title: "Static example", link: "#tooltips-static" },
              { title: "Live demo", link: "#tooltips-demo" }
            ]
          },
          {
            title: "Video Popup",
            link: "/ui_kit/video-popup",
            children: [
              { title: "Play button", link: "#video-btn" },
              { title: "Types and colors", link: "#video-btn-types-colors" },
              { title: "Sizing", link: "#video-btn-sizing" },
              { title: "On top of the image", link: "#video-btn-image" },
              { title: "Inside card", link: "#video-btn-card" }
            ]
          },
          {
            title: "Utilites",
            link: "/ui_kit/utilites",
            children: [
              { title: "Faded background colors", link: "#bg-faded" },
              { title: "Translucent image overlay", link: "#image-overlay" },
              { title: "Gradient text", link: "#gradient-text" },
              { title: "Opacity", link: "#opacity" }
            ]
          },
        ]
      };
    },
    components: {
      // Simplebar,
      // PerfectScrollbar,
    },
    mounted() {
      this.scrollToHash();
      this.scrollAsideToHash()

      this.$router.afterEach(() => {
        this.scrollToHash();
        this.$nextTick(() => this.scrollAsideToHash());
      });
    },
    methods: {
      scrollToHash() {
        const hash = this.$route.hash;
        if (hash) {
          const element = document.querySelector(hash);
          if (element) {
            const offset = 100;
            const elementPosition = element.getBoundingClientRect().top + window.scrollY - offset;
            window.scrollTo({ top: elementPosition, behavior: 'smooth' });
          }
        } else {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      },
      scrollAsideToHash(){
        const container = document.querySelector('.simplebar-scrollable-y');
        if (container) {
          const activeElement = container.querySelector('.nav-link.active');
          if (activeElement) {
            container.scrollTop = activeElement.offsetTop - 180;
          }
        }

        
      }
    }
  };
</script>

<style scoped>
.simplebar-scrollable-y{
  overflow-y: auto;
}
</style>
  