<template>
  <div v-if="locked" class="page-loading active">
    <div class="page-loading-inner">
      <div class="mx-auto" style="max-width: 300px;">
        <span class="fs-1 d-block">
          <i class="ai ai-lock-closed"></i>
        </span>
        <span class="fs-4 d-block mb-3 mt-1">Locked</span>
        <span>
          <input v-model="passKey" type="password" class="form-control form-control-lg text-center" style="letter-spacing: 14px;" placeholder="****" maxlength="4" />
        </span>
      </div>
    </div>
  </div>
  <div v-if="loading" class="page-loading active">
    <div class="page-loading-inner">
      <div class="page-spinner"></div>
      <span>Loading...</span>
    </div>
  </div>
  <router-view v-if="!locked"/>
  <a class="btn-scroll-top" :class="showButton ? 'show' : ''" @click="scrollToTop" aria-label="Scroll back to top" >
    <svg viewBox="0 0 40 40" fill="currentColor" xmlns="http://www.w3.org/2000/svg" >
      <circle cx="20" cy="20" r="19" fill="none" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" :style="circleStyle"></circle>
    </svg>
    <i class="ai-arrow-up"></i>
  </a>
  
</template>

<script>

import { mapState,
  // mapActions
 } from 'vuex';

 import Intercom from '@intercom/messenger-js-sdk';

export default {
  name: 'App',
  data() {
    return {
      // locked: false,
      passKey: '',
      currentColor: '#448c74',
      // isDarkMode: document.documentElement.getAttribute('data-bs-theme') === 'dark',
      showButton: false,
      scrollOffset: 450,
      length: 119.397,
      progress: null,
      dashoffset: 90,
    };
  },
  computed: {
    circleStyle() {
      return {
        strokeDasharray: `${this.length}px`,
        strokeDashoffset: `${this.dashoffset}px`,
      };
    },
    isLengthFour() {
      return this.passKey.length === 4;
    },
    ...mapState({
      currentUser: state => state.user,
      loading: state => state.loading,
      locked: state => state.locked,
    }),
    
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);

    Intercom({
      app_id: 'xdmevhr0',
    });
   
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  watch: {
    '$route' (to, from) {
      document.title = to.meta.title + ' | Gotchu' || 'Gotchu';
      console.log('from', from);
    },
    isLengthFour(newValue) {
      if (newValue) {
        this.handleFourCharacters();
      }else{
        //wrong passkey
        // logout or try again attempt 2/3

      }
    },
  },


  methods: {
    handleScroll() {
      const scrollPercent =
        (document.body.scrollTop + document.documentElement.scrollTop) /
        (document.documentElement.scrollHeight - document.documentElement.clientHeight);
      const draw = this.length * scrollPercent;
      this.dashoffset = this.length - draw;

      if (window.pageYOffset > this.scrollOffset) {
        this.showButton = true;
      } else {
        this.showButton = false;
      }
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    handleFourCharacters() {
      console.log('Four characters entered:', this.passKey);
      this.$store.dispatch('unlock', !(this.passKey == '1234'));
    },
    // ...mapActions(['unlockItem']),
  },
};
</script>

<style>
  svg:hover {
    cursor: pointer;
  }
  .intercom-lightweight-app{
    position: absolute;
    bottom: 60px;
  }

  #app {
    height: 100% !important;
  }

  .page-loading {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: all .4s .2s ease-in-out;
    transition: all .4s .2s ease-in-out;
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    z-index: 9999;
  }

  [data-bs-theme="dark"] .page-loading {
    background-color: #121519;
  }

  .page-loading.active {
    opacity: 1;
    visibility: visible;
  }

  .page-loading-inner {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out;
    opacity: 0;
  }

  .page-loading.active>.page-loading-inner {
    opacity: 1;
  }

  .page-loading-inner>span {
    display: block;
    font-family: "Inter", sans-serif;
    font-size: 1rem;
    font-weight: normal;
    color: #6f788b;
  }

  [data-bs-theme="dark"] .page-loading-inner>span {
    color: #fff;
    opacity: .6;
  }

  .page-spinner {
    display: inline-block;
    width: 2.75rem;
    height: 2.75rem;
    margin-bottom: .75rem;
    vertical-align: text-bottom;
    background-color: #d7dde2;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: spinner .75s linear infinite;
    animation: spinner .75s linear infinite;
  }

  [data-bs-theme="dark"] .page-spinner {
    background-color: rgba(255, 255, 255, .25);
  }

  @-webkit-keyframes spinner {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    50% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }

  @keyframes spinner {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    50% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
</style>
