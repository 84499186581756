import { createRouter, createWebHashHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout.vue'
import InternalLayout from '@/layouts/InternalLayout.vue'
import UIKitLayout from '@/layouts/UIKitLayout.vue'

import store from '../store';
import { getAuth } from 'firebase/auth';
import InternalNoSidebarLayout from '@/layouts/InternalNoSidebarLayout.vue';

const routes = [
  {
    path: '/',
    component: DefaultLayout,
    meta: {
      breadcrumb: 'Home',
      title: 'Home'
    },
    children: [
      {
        path: '',
        name: 'HomeView',
        component: () => import('../views/HomeView.vue'),
        meta: {
          breadcrumb: 'Home',
        }
      },
      {
        path: 'about',
        name: 'About',
        component: () => import('../views/AboutView.vue'),
        meta: {
          breadcrumb: 'About',
          title: 'About us'
        }
      },
      {
        path: 'contact',
        name: 'Contact',
        component: InternalNoSidebarLayout,
        meta: {
          title: 'Home'
        },
        children: [
          {
            path: '',
            name: 'Contact',
            component: () => import('../views/ContactsView.vue'),
            meta: {
              breadcrumb: 'Contact',
              title: 'Contact'
            }
          },
        ],
        
      },
      {
        path: 'help-center',
        name: 'Help Center',
        component: InternalNoSidebarLayout,
        meta: {
          title: 'Help Center'
        },
        children: [
          {
            path: '',
            name: 'Help Center',
            component: () => import('../views/HelpCenterView.vue'),
            meta: {
              breadcrumb: 'Help Center',
              title: 'Help Center'
            }
          },
        ],
        
      },
      
      
      {
        path: 'docs',
        name: 'docs',
        component: InternalNoSidebarLayout,
        children: [
          {
            path: 'privacy-policy',
            name: 'privacy-policy',
            component: () => import(/* webpackChunkName: "about" */ '../views/pages/PrivacyPolicyView.vue'),
            meta: {
              breadcrumb: 'Privacy Policy',
              title: 'Privacy Policy',
            }
          },
          {
            path: 'terms-and-conditions',
            name: 'terms-and-conditions',
            component: () => import('../views/pages/TermsAndConditionsView.vue'),
            meta: {
              breadcrumb: 'Terms and Conditions',
              title: 'Terms and Conditions',
            }
          },
          {
            path: 'loan-contract',
            name: 'loan-contract',
            component: () => import('../views/pages/LoanContractView.vue'),
            meta: {
              breadcrumb: 'Loan Contract',
              title: 'Loan Contract',
            }
          },
          {
            path: 'cancellation-policy',
            name: 'cancellation-policy',
            component: () => import('../views/pages/CancellationPolicy.vue'),
            meta: {
              breadcrumb: 'Cancellation Policy',
              title: 'Cancellation Policy',
            }
          },
          
        ]
      },
      
    ]
  },
  {
    path: '/dashboard/',
    component: InternalLayout,
    meta: {
      breadcrumb: 'Dashboard',
    },
    children: [
      {
        path: '',
        name: 'OverviewView',
        component: () => import('../views/dashboard/OverviewView.vue'),
        meta: {
          // breadcrumb: 'Overview',
          requiresAuth: true,
          title: 'Dashboard Overview',
        }
      },
      {
        path: 'borrow',
        name: 'BorrowMoneyView',
        component: () => import('../views/dashboard/BorrowMoneyView.vue'),
        meta: {
          breadcrumb: 'Borrow money',
          requiresAuth: true,
          title: 'Borrow Money',
        }
      },
      {
        path: 'payback',
        name: 'PaybackView',
        component: () => import('../views/dashboard/PaybackView.vue'),
        meta: {
          breadcrumb: 'Payback ',
          requiresAuth: true,
          title: 'Payback',
        }
      },
      {
        path: 'transactions',
        name: 'TransactionHistoryView',
        component: () => import('../views/dashboard/TransactionHistoryView.vue'),
        meta: {
          breadcrumb: 'Transaction history',
          requiresAuth: true,
          title: 'Borrow Money',
        }
      },

      
      {
        path: 'settings',
        name: 'ProfileSettings',
        component: () => import('../views/dashboard/ProfileSettingsView.vue'),
        meta: {
          breadcrumb: 'Profile',
          title: 'Profile',
          requiresAuth: true,
        }
      },
      {
        path: 'profile',
        name: 'UserProfile',
        component: () => import('../views/dashboard/UserDetailView.vue'),
        meta: {
          breadcrumb: 'Profile',
          title: 'Profile',
          requiresAuth: true,
        }
      },
      {
        path: 'user-list/',
        name: 'UserList',
        component: () => import('../views/dashboard/UserListView.vue'),
        meta: {
          breadcrumb: 'User List',
          title: 'User List',
          requiresAuth: true,
        }
      },
      {
        path: 'user/:id',
        name: 'UserDetail',
        component: () => import('../views/dashboard/UserDetailView.vue'),
        meta: {
          breadcrumb: 'User Details',
          title: 'User Details',
          requiresAuth: true,
          parent: 'User List',
        }
      },
      {
        path: 'chats',
        name: 'Chats',
        component: () => import('../views/dashboard/ChatsView.vue'),
        meta: {
          breadcrumb: 'Chats',
          title: 'Chats',
          requiresAuth: true,
        }
      },
    ]
  },
  {
    path: '/login',
    name: 'LoginView',
    component: () => import('../views/LoginView.vue'),
    meta: {
      title: 'Login',
    }
  },
  {
    path: '/signup',
    name: 'SignupView',
    component: () => import('../views/SignupView.vue'),
    meta: {
      title: 'Sign Up',
    }
  },
  
  {
    path: '/ui_kit/',
    component: UIKitLayout,
    redirect: '/ui_kit/typography',
    children: [
      {
        path: 'typography',
        name: 'TypographyView',
        component: () => import('../views/ui_kit/TypographyView.vue'),
        meta: {
          title: 'Icons',
        }
      },
      {
        path: 'icon-font',
        name: 'IconFontView',
        component: () => import('../views/ui_kit/IconFontView.vue')
      },
      {
        path: 'code',
        name: 'CodeView',
        component: () => import('../views/ui_kit/CodeView.vue')
      },
      {
        path: 'images',
        name: 'ImagesView',
        component: () => import('../views/ui_kit/ImagesView.vue')
      },
      {
        path: 'tables',
        name: 'TablesView',
        component: () => import('../views/ui_kit/TablesView.vue')
      },
      {
        path: 'accordion',
        name: 'AccordionView',
        component: () => import('../views/ui_kit/AccordionView.vue')
      },
      {
        path: 'alerts',
        name: 'AlertsView',
        component: () => import('../views/ui_kit/AlertsView.vue')
      },
      {
        path: 'badges',
        name: 'BadgesView',
        component: () => import('../views/ui_kit/BadgesView.vue')
      },
      {
        path: 'blog',
        name: 'BlogComponentsView',
        component: () => import('../views/ui_kit/BlogComponentsView.vue')
      },
      {
        path: 'breadcrumbs',
        name: 'BreadcrumbsView',
        component: () => import('../views/ui_kit/BreadcrumbsView.vue')
      },
      {
        path: 'buttons',
        name: 'ButtonsView',
        component: () => import('../views/ui_kit/ButtonsView.vue')
      },
      {
        path: 'button-group',
        name: 'ButtonGroupView',
        component: () => import('../views/ui_kit/ButtonGroupView.vue')
      },
      {
        path: 'cards-basic',
        name: 'CardsBasicView',
        component: () => import('../views/ui_kit/CardsBasicView.vue')
      },
      {
        path: 'cards-hover',
        name: 'CardsHoverView',
        component: () => import('../views/ui_kit/CardsHoverView.vue')
      },
      {
        path: 'carousel',
        name: 'CarouselView',
        component: () => import('../views/ui_kit/CarouselView.vue')
      },
      {
        path: 'charts',
        name: 'ChartsView',
        component: () => import('../views/ui_kit/ChartsView.vue')
      },
      {
        path: 'collapse',
        name: 'CollapseView',
        component: () => import('../views/ui_kit/CollapseView.vue')
      },
      {
        path: 'countdown',
        name: 'CountdownView',
        component: () => import('../views/ui_kit/CountdownView.vue')
      },
      {
        path: 'date-picker',
        name: 'DatepickerView',
        component: () => import('../views/ui_kit/DatepickerView.vue')
      },
      {
        path: 'dropdowns',
        name: 'DropdownsView',
        component: () => import('../views/ui_kit/DropdownsView.vue')
      },
      {
        path: 'calendar',
        name: 'EventCalendarView',
        component: () => import('../views/ui_kit/EventCalendarView.vue')
      },
      {
        path: 'forms',
        name: 'FormsView',
        component: () => import('../views/ui_kit/FormsView.vue')
      },
      {
        path: 'gallery',
        name: 'GalleryView',
        component: () => import('../views/ui_kit/GalleryView.vue')
      },
      {
        path: 'hotspots',
        name: 'HotspotsView',
        component: () => import('../views/ui_kit/HotspotsView.vue')
      },
      {
        path: 'image-comparison-slider',
        name: 'ImageComparisonSliderView',
        component: () => import('../views/ui_kit/ImageComparisonSliderView.vue')
      },
      {
        path: 'input-group',
        name: 'InputGroupView',
        component: () => import('../views/ui_kit/InputGroupView.vue')
      },
      {
        path: 'list-group',
        name: 'ListGroupView',
        component: () => import('../views/ui_kit/ListGroupView.vue')
      },
      {
        path: 'maps',
        name: 'MapsView',
        component: () => import('../views/ui_kit/MapsView.vue')
      },
      {
        path: 'modal',
        name: 'ModalView',
        component: () => import('../views/ui_kit/ModalView.vue')
      },
      {
        path: 'navbar',
        name: 'NavbarView',
        component: () => import('../views/ui_kit/NavbarView.vue')
      },
      {
        path: 'offcanvas',
        name: 'OffcanvasView',
        component: () => import('../views/ui_kit/OffcanvasView.vue')
      },
      {
        path: 'pagination',
        name: 'PaginationView',
        component: () => import('../views/ui_kit/PaginationView.vue')
      },
      {
        path: 'parallax',
        name: 'ParallaxView',
        component: () => import('../views/ui_kit/ParallaxView.vue')
      },
      {
        path: 'pills',
        name: 'PillsView',
        component: () => import('../views/ui_kit/PillsView.vue')
      },
      {
        path: 'placeholders',
        name: 'PlaceholdersView',
        component: () => import('../views/ui_kit/PlaceholdersView.vue')
      },
      {
        path: 'popovers',
        name: 'PopoversView',
        component: () => import('../views/ui_kit/PopoversView.vue')
      },
      {
        path: 'portfolio-components',
        name: 'PortfolioComponentsView',
        component: () => import('../views/ui_kit/PortfolioComponentsView.vue')
      },
      {
        path: 'pricing',
        name: 'PricingView',
        component: () => import('../views/ui_kit/PricingView.vue')
      },
      {
        path: 'progress',
        name: 'ProgressView',
        component: () => import('../views/ui_kit/ProgressView.vue')
      },
      {
        path: 'shop-components',
        name: 'ShopComponentsView',
        component: () => import('../views/ui_kit/ShopComponentsView.vue')
      },
      {
        path: 'social-buttons',
        name: 'SocialButtonsView',
        component: () => import('../views/ui_kit/SocialButtonsView.vue')
      },
      {
        path: 'spinners',
        name: 'SpinnersView',
        component: () => import('../views/ui_kit/SpinnersView.vue')
      },
      {
        path: 'steps',
        name: 'StepsView',
        component: () => import('../views/ui_kit/StepsView.vue')
      },
      {
        path: 'tabs',
        name: 'TabsView',
        component: () => import('../views/ui_kit/TabsView.vue')
      },
      {
        path: 'team',
        name: 'TeamView',
        component: () => import('../views/ui_kit/TeamView.vue')
      },
      {
        path: 'testimonials',
        name: 'TestimonialsView',
        component: () => import('../views/ui_kit/TestimonialsView.vue')
      },
      {
        path: 'toasts',
        name: 'ToastsView',
        component: () => import('../views/ui_kit/ToastsView.vue')
      },
      {
        path: 'tooltips',
        name: 'TooltipsView',
        component: () => import('../views/ui_kit/TooltipsView.vue')
      },
      {
        path: 'video-popup',
        name: 'VideoPopupView',
        component: () => import('../views/ui_kit/VideoPopupView.vue')
      },
      {
        path: 'utilites',
        name: 'UtilitesView',
        component: () => import('../views/ui_kit/UtilitesView.vue')
      },
    ]
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('../views/errors/NotFound.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes,
  linkActiveClass: 'active1',
  linkExactActiveClass: 'active',
});


router.beforeEach(async (to, from, next) => {
  // document.title = to.meta.title + ' | Gotchu' || 'Gotchu';
  await store.dispatch('checkAuth');
  const auth = getAuth();
  const user = auth.currentUser;

  if (to.matched.some(record => record.meta.requiresAuth) && !user) {
    next('/login');
  } else if (to.path === '/login' && user) {
    next('/');
  } else {
    next();
  }
  // Scroll to top
  window.scrollTo(0, 0);
});

export default router
