<template>
    <NavBarVue></NavBarVue>

    <div class="container pt-5 pb-lg-5 pb-md-4 pb-2 my-5">
      <div class="row mb-md-2 mb-xl-4">
        <div class="col-lg-9 pe-lg-4 pe-xl-5">
          
            <BreadcrumbNavigation />
            
            <router-view/>
        </div>

        <aside class="col-lg-3">
          <div class="offcanvas-lg offcanvas-end" id="sidebarBlog">
            <div class="offcanvas-header">
              <h4 class="offcanvas-title">Sidebar</h4>
              <button class="btn-close ms-auto" type="button" data-bs-dismiss="offcanvas" data-bs-target="#sidebarBlog"
                aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">

              <!-- Search box -->
              <!-- <div class="position-relative mb-4 mb-lg-5">
                <i class="ai-search position-absolute top-50 start-0 translate-middle-y ms-3"></i>
                <input class="form-control ps-5" type="search" placeholder="Enter keyword">
              </div> -->

              

              <!-- Banner -->
              <!-- <div class="position-relative mb-3">
                <div class="position-absolute w-100 text-center top-0 start-50 translate-middle-x pt-4"
                  style="max-width: 15rem;" data-bs-theme="light">
                  <h3 class="h2 pt-3 mb-0">Your banner here!</h3>
                </div>
                <img class="rounded-5" src="@/assets/img/blog/sidebar/banner.jpg" alt="Banner">
              </div> -->
            </div>
            <span class="badge m-2">
              <i class="ai ai-bell"></i> Notifications & Updates
            </span>
            <div class="list-group">
              <div class="list-group-item">
                Edit user functionality improved for better management.
              </div>
              <div class="list-group-item">
                Added a filter option to easily search and sort the user list.
              </div>
              <div class="list-group-item">
                Transaction details view enhanced for clearer insights.
              </div>
              <div class="list-group-item">
                Chat feature now fetches chats, messages, marks messages as seen, and automatically scrolls to the last message. New messages trigger an update.
              </div>
              <div class="list-group-item">
                Chat system fetches chats and messages, marks messages as seen.
              </div>
              <div class="list-group-item bg-danger">
                Live message update feature added—messages update instantly when received.
              </div>
              <div class="list-group-item">
                User dashboard now includes an overview section for better summary of activities.
              </div>
              <div class="list-group-item">
                User calculator added to the home page for quick financial calculations.
              </div>
              <div class="list-group-item">
                Transaction history page is now more detailed and user-friendly.
              </div>
              <div class="list-group-item">
                Borrow money page redesigned for a smoother experience.
              </div>
              <div class="list-group-item">
                Added a payback option with clear repayment details.
              </div>
            </div>

          </div>
        </aside>
        
      </div>
    </div>

    

    <FooterBlock></FooterBlock>
  </template>
  
  <script>
  import FooterBlock from "../components/FooterBlock.vue";
  import NavBarVue from "../components/NavBar.vue";
  import BreadcrumbNavigation from '@/components/BreadcrumbNavigation.vue';


  export default {
    name: 'InternalLayout',
    components: {
      NavBarVue,
      FooterBlock,
      BreadcrumbNavigation
    },
  };
  </script>
  
  
  <style>
  </style>
  