<template>

    <NavBarVue></NavBarVue>

    <AsideMenu></AsideMenu>

    <router-view/>

  </template>
  
  <script>
  import AsideMenu from "@/components/ui/AsideMenu.vue";
  import NavBarVue from "../components/NavBar.vue";
  
  export default {
    name: 'App',
    components: {
      NavBarVue,
      AsideMenu,
    },
  };
  </script>
  
  
  <style>
  </style>
  