import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// import 'bootstrap/dist/css/bootstrap.css'
// import './assets/scss/bootstrap.scss';
// import 'bootstrap/scss/bootstrap.scss';

import './assets/css/theme.css';
import './assets/css/custom-scrollbar.css';
import './assets/css/around-icons.min.css';

import 'jquery/src/jquery.js';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import 'fullcalendar/index.js';

import AOS from 'aos';
import 'aos/dist/aos.css';

import { db, auth } from './firebase'; // Import Firebase services

// import './registerServiceWorker'


// import VueLazyload from 'vue-lazyload';
// import './assets/js/theme.js';


const app = createApp(App);

// Initialize theme from localStorage
const storedTheme = localStorage.getItem('theme');
if (storedTheme) {
  document.documentElement.setAttribute('data-bs-theme', storedTheme);
}

// app.use(VueLazyload, {
//   preLoad: 1.3,
//   error: 'path/to/error.png',
//   loading: 'path/to/loading.gif',
//   attempt: 1
// });

app.use(store);
app.use(router);


// You can now use `db` and `auth` anywhere in your Vue components
app.config.globalProperties.$db = db;
app.config.globalProperties.$auth = auth;


app.mount('#app')
AOS.init();
// Check if user logged in
store.dispatch('checkAuth');



