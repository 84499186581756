<template>
    <NavBarVue></NavBarVue>
    <router-view/>
    <FooterBlock></FooterBlock>
  </template>
  
  <script>
  import FooterBlock from "../components/FooterBlock.vue";
  import NavBarVue from "../components/NavBar.vue";


  export default {
    name: 'App',
    components: {
      NavBarVue,
      FooterBlock,
    },
  };
  </script>
  
  
  <style>
  </style>
  